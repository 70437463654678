<template>
  <BaseLayout :title="!invite ? title : '注册账号'" bg="linear-gradient(180deg, #EFF, #F9F9F9 10%, #F9F9F9)" :height="!invite? '60px': 0" @click-back="onBack">
    <template #back><van-icon :name="invite?'wap-home-o':'arrow-left'" size="large"></van-icon></template>

    <van-image :src="require('@/assets/invite_title.jpg')" v-show="invite"></van-image>
    <van-steps class="step" v-show="invite">
      <van-step>注册账号</van-step>
      <van-step>下载 app</van-step>
      <van-step>登录使用</van-step>
    </van-steps>

    <van-form class="form" @submit="onSubmit" @failed="onFailed">
      <van-field
        v-show="action != 'login'"
        v-model="state.account"
        name="mobile"
        label="手机号"
        placeholder="请填写手机号"
        clearable
        :rules="[{ required: action != 'login', message: '支持大陆及港澳台手机号码' }]"
      >
        <template #button>
          <van-button size="small" type="primary" @click="onSend">发送验证码</van-button>
        </template>
      </van-field>
      <van-field 
        v-show="action != 'login'"
        v-model="state.sms" 
        name="vcode"
        label="验证码" 
        :rules="[{ required: action != 'login', message: '请填写短信验证码' }]"
      />

      <van-field
        v-show="action == 'login'"
        v-model="state.account"
        name="username"
        label="账号"
        clearable
        :placeholder="action == 'login' ? '手机号或用户名' : '请填写用户名'"
        :rules="[{ required: action == 'login', message: '用户名以字母开头且长度不小于4' }]"
      />
      <van-field
        v-model="state.password"
        type="password"
        name="password"
        label="密码"
        clearable
        :placeholder="action == 'changepassword' ? '请填写新的密码' : '请填写密码'"
        :rules="[{ required: true, message: '包含字母和数字且长度不小于6' }]"
      />

      <van-field
        v-show="action == 'invite'"
        v-model="state.code"
        name="code"
        label="邀请码"
        placeholder="没有可以不用填写"
      />

      <br/>
      <van-button block type="primary" native-type="submit">{{title}}</van-button>

      <div class="extra" v-show="!invite && action=='login'"   >没有账号？<router-link class="register" to="/register">注册</router-link><router-link class="right"  to="/changepassword">忘记密码？</router-link></div>
      <div class="extra" v-show="!invite && action=='register'">已有账号？<router-link class="login" to="/login">登录</router-link></div>

    </van-form>

    <div class="intro">
      <van-image v-show="invite" :src="require('@/assets/invite_intro.jpg')" height="1000px"></van-image>
    </div>

  </BaseLayout>
</template>

<style scoped>
.step {
  margin: 10px;
}
.form {
  margin: 10px auto;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
}
.extra {
  margin-top: 20px;
  padding: 0 10px;
  color: #999;
  font-size: 14px;
}
.right {
  float: right;
}
.intro {
  margin-top: 20px;
}
</style>

<script>
import {reactive} from 'vue'
import {mapState, mapGetters, mapMutations} from 'vuex'
import BaseLayout from '@/components/BaseLayout.vue'

export default {
  components: {BaseLayout},

  props: {
    action: {
      type: String,
      default: "login",
    },
    code: {
      type: String,
      default: "",
    },
    redirect: {
      type: String,
      default: "/my",
    }
  },

  setup(){
    const state = reactive({
      account: "test",
      password: "Test123",
      code: "",
      sms: "",
    });

    return {
      state,
    };
  },

  created(){
    this.state.code = this.code;
  },

  mounted(){
    if (this.action == "logout"){
      let me = this;
      this.$http.post("/user/" + this.action).then((data) => {
        me.auth(data);
        me.$router.push(me.redirect);
      });
    }
  },

  computed: {
    invite(){
      return this.action == "invite" ? true : false;
    },
    title() {
      let act = this.action;
      let arr = {
        "login" : "登录",
        "register" : "注册",
        "changepassword" : "找回密码",
      }
      if (this.invite)
        return "注册";
      if (typeof arr[act] == "undefined")
        return "登录";
      return arr[act];
    },
    ...mapState(["profile"]),
    ...mapGetters(["authorized"]),
  },

  methods: {
    onBack: function(){
      if (this.invite)
        return this.$router.push("/");
      return this.$router.push("/my");
    },
    onSend: function(){
      this.$http.post("/user/verify?mobile=" + this.state.account);
    },
    onSubmit: function(values) {
      let me = this;
      let action = this.action != "invite" ? this.action : "register";
      /^\d+$/.test(this.state.account) && delete values["username"] || delete values["mobile"];
      this.$http.post("/user/" + action, values).then((data) => {
        me.auth(data);
        me.$router.push(me.redirect);
      });
    },
    onFailed(err){
      this.$toast("请按要求填写表单");
      console.error("表单提交失败", err);
    },
    ...mapMutations(["auth"]),
  }

}

</script>
